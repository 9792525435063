import React from "react"
import SectionHeading from "../../../../components/shared/Sectionheading"
import { Col, Container, Image, Row } from "react-bootstrap"
import Paragraph from "../../../../components/shared/Paragraph"
import SubHeading from "../../../../components/shared/SubHeading"
import blog_img from "../../../../assets/images/mind_cards_img1.png"

const BlogDetail = () => {
	return (
		<Container>
			<Row>
				<Col md={8} className="mt-5">
					<SectionHeading>What is Mental Strength Training?</SectionHeading>
					<Paragraph className="mt-3">
						Athletes are often told that their attitude and mindset influence their play, but they aren’t always taught
						the mental tools that will help them use the connection between their mind and body to their advantage.
						Learning how to harness both sides of the mind-body equation is incredibly powerful. Athletes can work on
						the mental side of their game on their own or with the support of a mental performance coach. Working on
						mental strength can help improve physical performance, sport enjoyment, as well as commitment and
						motivation. All athletes regardless of their level and performance ability – from those who perform well in
						practice but choke during games to those who want to unlock their full potential – can benefit from working
						on their mental game.
					</Paragraph>
					<Image src={blog_img} className="w-100 mt-5" fluid />
					<SubHeading className="mt-5">The Mind-Body Connection</SubHeading>
					<Paragraph>
						Athletes are often told that their attitude and mindset influence their play, but they aren’t always taught
						the mental tools that will help them use the connection between their mind and body to their advantage.
						Learning how to harness both sides of the mind-body equation is incredibly powerful. Athletes can work on
						the mental side of their game on their own or with the support of a mental performance coach. Working on
						mental strength can help improve physical performance, sport enjoyment, as well as commitment and
						motivation. All athletes regardless of their level and performance ability – from those who perform well in
						practice but choke during games to those who want to unlock their full potential – can benefit from working
						on their mental game.
					</Paragraph>
					<SubHeading className="mt-5">Areas of Mental Strength Training</SubHeading>
					<Paragraph>
						Athletes are often told that their attitude and mindset influence their play, but they aren’t always taught
						the mental tools that will help them use the connection between their mind and body to their advantage.
						Learning how to harness both sides of the mind-body equation is incredibly powerful. Athletes can work on
						the mental
					</Paragraph>
					<SubHeading className="mt-5">Attentional focus</SubHeading>
					<Paragraph>
						Athletes are often told that their attitude and mindset influence their play, but they aren’t always taught
						the mental tools that will help them use the connection between their mind and body to their advantage.
						Learning how to harness both sides of the mind-body equation is incredibly powerful. Athletes can work on
						the mental side of their game on their own or with the support of a mental performance coach. Working on
						mental strength can help improve physical performance, sport enjoyment, as well as commitment and
						motivation. All athletes regardless of their level and performance ability – from those who perform well in
						practice but choke during games to those who want to unlock their full potential – can benefit from working
						on their mental game.
					</Paragraph>
					<SubHeading className="mt-5"> Imagery/Visualization</SubHeading>
					<Paragraph>
						Athletes are often told that their attitude and mindset influence their play, but they aren’t always taught
						the mental tools that will help them use the connection between their mind and body to their advantage.
						Learning how to harness both sides of the mind-body equation is incredibly powerful. Athletes can work on
						the mental side of their game on their own or with the support of a mental performance coach. Working on
						mental strength can help improve physical performance, sport enjoyment, as well as commitment and
						motivation. All athletes regardless of their level and performance ability – from those who perform well in
						practice but choke during games to those who want to unlock their full potential – can benefit from working
						on their mental game.
					</Paragraph>
				</Col>
				<Col md={4} className="mt-5"></Col>
			</Row>
		</Container>
	)
}

export default BlogDetail
