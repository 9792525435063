import React from "react"
import { Main } from "../components/layout"
import BlogDetail from "../components/pages/blog/BlogDetial"

const BlogDetailPage = () => {
	return (
		<Main>
			<BlogDetail />
		</Main>
	)
}

export default BlogDetailPage
